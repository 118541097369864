import gql from 'graphql-tag'

export const seoFragment = gql`
  fragment SeoFragment on SeoField {
    image {
      url(imgixParams: { w: "1200", h: "630" })
    }
    description
    title
    noIndex
  }
`
