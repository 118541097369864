import gql from 'graphql-tag'

import {
  projectListingFragment,
  projectFragment,
} from '../fragments/projects.js'
import { seoFragment } from '../fragments/seo.js'

const projectImageFragment = gql`
  fragment ProjectImageFragment on ResponsiveImage {
    webpSrcSet
    src
    alt
    title
    height
    width
    sizes
  }
`

export const getProjects = gql`
  ${projectListingFragment}
  ${seoFragment}
  query GetProjects(
    $locale: SiteLocale
    $filter: ProjectModelFilter
    $first: IntType
    $skip: IntType
  ) {
    worksPage(locale: $locale) {
      title
      subtitle
      seo {
        ...SeoFragment
      }
    }
    allProjects(locale: $locale, filter: $filter, first: $first, skip: $skip) {
      ... on ProjectRecord {
        ...ProjectListingFragment
      }
    }

    _allProjectsMeta(locale: $locale, filter: $filter) {
      count
    }
  }
`

export const getProject = gql`
  ${projectFragment}
  ${projectImageFragment}
  ${projectListingFragment}
  ${seoFragment}
  query GetProject(
    $locale: SiteLocale
    $filter: ProjectModelFilter
    $siblinsLimit: IntType
    $siblingsFilter: ProjectModelFilter
  ) {
    project(locale: $locale, filter: $filter) {
      ... on ProjectRecord {
        ...ProjectFragment

        _allSlugLocales(fallbackLocales: it) {
          value
          locale
        }
        body {
          ... on TwoColGridRecord {
            rightColumn {
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                ) {
                  ...ProjectImageFragment
                }
              }
              linkVideo
              isVertical
            }
            leftColumn {
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                ) {
                  ...ProjectImageFragment
                }
              }
              linkVideo
              isVertical
            }
          }
          ... on ImageVideoBlockRecord {
            image {
              id
              responsiveImage(
                imgixParams: { ar: "16:9", fit: crop, w: "3800", fm: webp }
              ) {
                ...ProjectImageFragment
              }
            }
            linkVideo
            linkVideoPreview
            smallHeight
            isVertical
          }
          ... on ThreeColGridRecord {
            rightColumn {
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "1500", fm: webp }
                ) {
                  ...ProjectImageFragment
                }
              }
              linkVideo
              isVertical
            }
            leftColumn {
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "1500", fm: webp }
                ) {
                  ...ProjectImageFragment
                }
              }
              linkVideo
              isVertical
            }
            centerColumn {
              image {
                id
                responsiveImage(
                  imgixParams: { ar: "16:9", fit: crop, w: "1500", fm: webp }
                ) {
                  ...ProjectImageFragment
                }
              }
              linkVideo
              isVertical
            }
          }
        }
      }
      seo {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    allProjects(locale: $locale, filter: $siblingsFilter, first: $siblinsLimit) {
      ...ProjectListingFragment
    }
  }
`
